// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/bootstrap-select/sass/bootstrap-select.scss";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone";
@import "node_modules/timepicker/jquery.timepicker";

hr {
  margin: 0 0 8px 0;
}

.no-stretch {
  width: 1%;
  white-space: nowrap;
}

.no-margin {
  margin: 0;
}

h4, h3, h5 {
  margin: 0 0 5px 0;
}

.form-control-inline {
  width: auto;
  display: inline;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.panel-condensed .panel-heading {
  padding: 5px 10px;
}

.acuerdos-panel {
  max-height: 100px;
  overflow: auto;
  padding: 0;
}

.acuerdos-panel .table {
  margin-bottom: 0;
}

.clickable:hover {
  cursor: pointer;
}

//navbar

.navbar {
  background-image: linear-gradient(to bottom, #15497B 0%, #00a7e1 100%);
}

//Transitions

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0
}

//

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */
{
  transform: translatex(-10px);
  opacity: 0;
}

//

.slide-left-enter-active {
  transition: all .2s ease;
}

.slide-left-leave-active {
  transition: all .2s ease;
}

.slide-left-enter, .slide-left-leave-to
  /* .slide-fade-leave-active for <2.1.8 */
{
  transform: translatex(50px);
  opacity: 0;
}

//Llenar cedula

.pregunta {
  border-color: #BBB;
}

.pregunta .panel-body {
  padding: 0;
}

.pregunta .pregunta-title {
  border-bottom: 1px solid #BBB;
}

.pregunta .pregunta-title table {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.pregunta .pregunta-title .title-left {
  border-top-left-radius: 4px;
  width: 40px;
  height: 100%;
  background-color: rgb(0, 74, 129);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
}

.pregunta .pregunta-title .title-right {
  border-top-right-radius: 4px;
  background-color: #ddd;
  color: #666;
  font-size: 15px;
  padding-left: 10px;
}

.pregunta .pregunta-body {
  padding: 10px;
  margin: 10px 0;
}

.pregunta .pregunta-body .help-block {
  margin: 2px 0 10px 10px;
}

.pregunta .pregunta-body .check {
  border: 1px solid #666;
  border-radius: 2px;
  width: 1.5em;
  height: 1.5em;
  margin: 0 5px;
  padding-left: 1px;
}

.pregunta .comment-button {
  margin-left: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.share-frame {
  background-color: #f5f8fa;
  border-left: 1px solid #ccc;
  height: 100%;
  position: fixed;
  top: 51px;
  right: 0;
}

.share-frame .close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.share-head {
  margin-top: 25px;
}

.share-head .text {
  margin-left: 10px;
}

.share-body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.share-body .shares {
  margin-top: 10px;
}

.shares .share {
  position: relative;
  margin-bottom: 10px;
}

.share .share-panel {
  padding: 5px 25px 5px 10px;
}

.share .share-delete {
  background-color: #d9534f;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 2px;
}

.archivo .archivo-icon-box {
  position: relative;
}

.archivo .archivo-checkbox {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.delete-bar {
  padding: 5px 10px;
}

table thead tr {
  background-color: #0F669B;
  color: white;
}

.pointer {
  cursor: pointer;
}

.header_tbl_ipaot {
  background-color: #85c5e5;
  width: 100%;
  color: white;
  height: 30px
}

.header_tbl_criterios {
  background-color: #85c5e5;
  width: 100%;
  height: 30px;
  border-left: 2px solid #CCCCCC
}

.componente {
  background-color: #006586;
  color: white
}
.text-middle{
  vertical-align: middle;
}