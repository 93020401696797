// Global
$body-bg: #f5f8fa;
$text-color: rgb(0, 74, 129);
$link-color: rgb(238, 61, 150);

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #00a7e1;
$brand-info: #62A3CB;
//$brand-success: #8dc63f;
$brand-success: #00a56c;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;
//Font-awesome
$fa-font-path: "~font-awesome/fonts" !default;

// Navbar
$navbar-default-bg: #15497B;
$navbar-default-color: white;
$navbar-default-link-color: white;
$navbar-default-link-hover-color: #ccc;
$navbar-default-link-active-color: #ccc;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 5%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #eee;

// Tables
$table-bg: #fff;
$table-bg-accent: #f9f9f9;
$table-border-color: #ccc;

